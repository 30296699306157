.logincontainer{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loginform{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(105, 105, 105, 0.295);
    width: fit-content;
    height: fit-content;
    background-color: rgb(143, 143, 143);
}

.submitbutto{
    width: fit-content;
}