.services-page{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    background: linear-gradient(90deg, #008c45 0 34%, #f4f5f0 34% 64%, #cd212a 64% 100%);
}

.service-container{
    display: flex;
    color: white;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: #202020fd;
    border-radius: 25px;
    padding:25px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    box-shadow: 2px 5px 10px rgba(39, 39, 39, 0.349);
}
.slide-card-left{
    display: flex;
    flex-direction: column;
}
.spag-pictures{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 15px;
    flex-wrap: wrap;
}
.s-img{
    width: 250px;
    height: 256px;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.342);
    border: 2px solid rgb(0, 0, 0);
    border-radius: 15px;

}