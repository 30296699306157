.home-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.title-banner{
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #202020;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    box-shadow: 0px 5px 10px rgb(80, 80, 80);
}

.club-title{
    text-decoration: underline;
    color: white;

}

.club-description{
    text-decoration: underline;
    color: white;

}

.carousel-container{
    background-color: rgba(255, 255, 255, 0);
    height: 100%;
    border-radius: 10px;
}

.test{
    aspect-ratio: 1500/661;
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.cc{
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(43, 43, 43, 0.507);
    margin-bottom: 20px;
}

.logo-image{
    height: 100%;
    width: 100%;    
}