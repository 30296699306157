.newpost{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pbox{
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border-radius: 20px;
    box-shadow: 0px 10px 10px rgba(156, 156, 156, 0.452);
    width: fit-content;
    height: fit-content;
    background-color: rgb(153, 153, 153);
}

.submitbutto{
    width: fit-content;
}